export const codingBootcampClasses = [
  {
    title: '🚩 HTML/CSS에서 JS까지! 웹개발 한 번에 끝내기',
    description: '믿을 수 있는 위코드의 커리큘럼! HTML/CSS부터 JS까지 순서대로 공부하며 웹개발의 모든 것을 체계적으로 배워보세요!',
    items: [
      {
        sequence: 1,
        courseId: 4,
        title: 'CSS Display 속성 처음부터 끝까지 완전 정복',
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course4-main-thumbnail-small.png',
        description: '웹사이트 레이아웃, 혼자 만들다가 길을 잃진 않으셨나요? Display 속성 입문자를 위한 강의예요!',
        price: '₩ 3,000',
        chaptersCount: 7,
        tags: ['Block', 'Inline'],
      },
      {
        sequence: 2,
        courseId: 7,
        title: '오늘부터 바로 써먹는 CSS Flex 필수 가이드',
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course7-main-thumbnail-small.png',
        description: 'Flex로 웹사이트를 만드는 진짜 방법을 배워보세요. 오늘부터 바로 써먹을 수 있는 Flex를 알려드려요!',
        price: '₩ 3,000',
        chaptersCount: 7,
        tags: ['Display', 'Flex'],
      },
      {
        sequence: 3,
        courseId: 13,
        title: '6가지 응용 예제로 이해하는 CSS Margin & Padding',
        imageSrc: 'https://cdn1.wecode.co.kr/wecode/courses/13/assets/Course13-main-thumbnail-small.png',
        description: '웹사이트 레이아웃을 만드는 가장 기본적인 방법인 박스모델의 기초 응용방법을 배웁니다.',
        price: '₩ 3,000',
        chaptersCount: 6,
        tags: ['Margin', 'Padding'],
      },
      {
        sequence: 4,
        courseId: 14,
        title: 'CSS Position, 기본부터 응용까지 탄탄하게',
        imageSrc: 'https://cdn1.wecode.co.kr/wecode/courses/14/assets/Course14-main-thumbnail-small.png',
        description: 'Position 속성을 이해하고, 실제로 사용하는 방법을 배워보세요. 웹사이트 레이아웃을 다양하게 만들어보아요!',
        price: '₩ 3,000',
        chaptersCount: 6,
        tags: ['Position', 'Static', 'Relative', 'Absolute', 'Fixed'],
      },
      {
        sequence: 5,
        courseId: 11,
        title: '실전 JS - 브라우저 이벤트와 핸들러 함수 완전 정복',
        imageSrc: 'https://cdn1.wecode.co.kr/wecode/courses/11/assets/course_11_description_image_3.png',
        description: 'JavaScript를 사용해서 브라우저 이벤트와 핸들러 함수를 실제로 사용하는 법을 배웁니다!',
        price: '₩ 3,000',
        chaptersCount: 6,
        tags: ['이벤트', '함수'],
      },
      {
        sequence: 6,
        courseId: 9,
        title: '실전 응용을 통해 배우는 JS배열과 반복문',
        imageSrc: 'https://cdn1.wecode.co.kr/wecode/courses/9/assets/course9_description_image_2.png',
        description: '웹개발에서 가장 자주 사용되는 JavaScript 배열과 반복문을 다양한 방법으로 활용해보아요!',
        price: '₩ 3,000',
        chaptersCount: 6,
        tags: ['배열', '반복문'],
      },
      {
        sequence: 7,
        courseId: 10,
        title: '실전 활용에 초점을 맞춘 자바스크립트 함수와 객체',
        imageSrc: 'https://cdn1.wecode.co.kr/wecode/courses/10/assets/course_10_description_image_2.png',
        description: `단순 이론에서 벗어나 JS 함수와 객체를 실제 웹개발에서 사용하는 법을 배웁니다!`,
        price: '₩ 3,000',
        chaptersCount: 6,
        tags: ['함수', '객체']
      },
      {
        sequence: 8,
        courseId: 12,
        title: '브라우저 저장소, 응용과 활용으로 완벽 마스터',
        imageSrc: 'https://cdn1.wecode.co.kr/wecode/courses/12/assets/Course12-main-thumbnail-small.png',
        description: '로컬스토리지, 세션스토리지, 쿠키 세가지 브라우저 저장소가 실제 사용되는 예시들을 쉽게 이해해보세요!',
        price: '₩ 3,000',
        chaptersCount: 6,
        tags: ['쿠키', '세션', '로컬 스토리지'],
      }
    ]
  },
];

export const classPromotions = [
  {
    title: "⏳ 곧 열려요! 핵심 내용에 집중한 개별 강의",
    description: '내게 맞는 강의를 찾고 계시나요? 더 깊은 지식을 배우고 싶으신가요? Wecode가 여러분을 위해 딱 맞춰 준비한 강의를 곧 만나보세요!',
    items: [
      {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course13-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      },
      {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course14-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      }, {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course15-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      }, {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course16-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      }, {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course17-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      }, {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course18-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      }, {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course19-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      }, {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course20-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      }, {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course21-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      }
    ]
  }
];
